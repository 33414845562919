<template>
  <div
    ref="clockRef"
    class="v-time-picker-clock"
    :class="{
      'v-time-picker-clock--indeterminate': modelValue == null,
      'v-time-picker-clock--readonly': readonly,
    }"
    @mousedown="onMouseDown"
    @touchstart="onMouseDown"
    @wheel="wheel"
  >
    <div
      ref="innerClockRef"
      class="v-time-picker-clock__inner"
    >
      <div
        class="v-time-picker-clock__hand"
        :class="[
          {
            'v-time-picker-clock__hand--inner': isInner(modelValue),
          },
          textColorClasses,
        ]"
        :style="[
          {
            transform: `rotate(${rotate + degreesPerUnit * (displayedValue - min)}deg) scaleY(${handScale(displayedValue)})`,
          },
          textColorStyles,
        ]"
      />

      <div
        v-for="value in genChildren"
        :key="value"
        class="v-time-picker-clock__item"
        :class="{
          'v-time-picker-clock__item--active': value === displayedValue,
          'v-time-picker-clock__item--disabled': disabled || !isAllowed(value),
          ...(value === displayedValue ? backgroundColorClasses : {}),
        }"
        :style="[getTransform(value), ...(value === displayedValue ? [backgroundColorStyles] : [])]"
      >
        <span>{{ format(value) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VuetifyTimePicker',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    allowedValues: Function,
    ampm: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    color: String,
    disabled: Boolean,
    // displayedValue: Number,
    double: Boolean,
    format: {
      type: Function,
      default: (val) => val,
    },
    // eslint-disable-next-line vue/require-default-prop
    max: Number,
    // eslint-disable-next-line vue/require-default-prop
    min: Number,
    scrollable: Boolean,
    readonly: Boolean,
    rotate: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    // eslint-disable-next-line vue/require-default-prop
    modelValue: Number,
  },
  data() {
    return {
      inputValue: undefined,
      isDragging: false,
      valueOnMouseDown: null,
      valueOnMouseUp: null,

      //
      textColorStyles: {},
      textColorClasses: {},
      backgroundColorStyles: {},
      backgroundColorClasses: {},
    }
  },
  computed: {
    count() {
      return this.max - this.min + 1
    },
    roundCount() {
      return this.double ? this.count / 2 : this.count
    },
    degreesPerUnit() {
      return 360 / this.roundCount
    },
    degrees() {
      return (this.degreesPerUnit * Math.PI) / 180
    },
    displayedValue() {
      return this.modelValue == null ? this.min : this.modelValue
    },
    innerRadiusScale() {
      return 0.62
    },
    genChildren() {
      const children = []
      for (let value = this.min; value <= this.max; value += this.step) {
        children.push(value)
      }
      return children
    },
  },
  watch: {
    modelValue(val) {
      this.inputValue = val
    },
  },
  methods: {
    update(value) {
      if (this.inputValue !== value) {
        this.inputValue = value
      }
      this.$emit('input', value)
    },
    isAllowed(value) {
      return !this.allowedValues || this.allowedValues(value)
    },
    wheel(e) {
      if (!this.scrollable || this.disabled) return
      e.preventDefault()
      let delta = Math.sign(-e.deltaY || 1)
      let value = this.displayedValue
      do {
        value += delta
        value = ((value - this.min + this.count) % this.count) + this.min
      } while (!this.isAllowed(value) && value !== this.displayedValue)
      if (value !== this.displayedValue) {
        this.update(value)
      }
    },
    isInner(value) {
      return this.double && value - this.min >= this.roundCount
    },
    handScale(value) {
      return this.isInner(value) ? this.innerRadiusScale : 1
    },
    getPosition(value) {
      const rotateRadians = (this.rotate * Math.PI) / 180
      return {
        x: Math.sin((value - this.min) * this.degrees + rotateRadians) * this.handScale(value),
        y: -Math.cos((value - this.min) * this.degrees + rotateRadians) * this.handScale(value),
      }
    },
    angleToValue(angle, insideClick) {
      const value =
          ((Math.round(angle / this.degreesPerUnit) + (insideClick ? this.roundCount : 0)) %
              this.count) +
          this.min

      if (angle < 360 - this.degreesPerUnit / 2) return value

      return insideClick ? this.max - this.roundCount + 1 : this.min
    },
    getTransform(i) {
      const { x, y } = this.getPosition(i)
      return {
        left: `${50 + x * 50}%`,
        top: `${50 + y * 50}%`,
      }
    },
    euclidean(p0, p1) {
      const dx = p1.x - p0.x
      const dy = p1.y - p0.y

      return Math.sqrt(dx * dx + dy * dy)
    },
    angle(center, p1) {
      const value = 2 * Math.atan2(p1.y - center.y - this.euclidean(center, p1), p1.x - center.x)
      return Math.abs((value * 180) / Math.PI)
    },
    setMouseDownValue(value) {
      if (this.valueOnMouseDown === null) {
        this.valueOnMouseDown = value
      }

      this.valueOnMouseUp = value
      this.update(value)
    },
    onDragMove(e) {
      e.preventDefault()
      if ((!this.isDragging && e.type !== 'click') || !this.$refs['clockRef']) return
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { width, top, left } = this.$refs['clockRef']?.getBoundingClientRect()
      const { width: innerWidth } = this.$refs['innerClockRef']?.getBoundingClientRect() ?? ({ width: 0 })
      const { clientX, clientY } = 'touches' in e ? e.touches[0] : e
      const center = { x: width / 2, y: -width / 2 }
      const coords = { x: clientX - left, y: top - clientY }
      const handAngle = Math.round(this.angle(center, coords) - this.rotate + 360) % 360
      const insideClick =
          this.double &&
          this.euclidean(center, coords) < (innerWidth + innerWidth * this.innerRadiusScale) / 4
      const checksCount = Math.ceil(15 / this.degreesPerUnit)
      let value

      for (let i = 0; i < checksCount; i++) {
        value = this.angleToValue(handAngle + i * this.degreesPerUnit, insideClick)
        if (this.isAllowed(value)) return this.setMouseDownValue(value)

        value = this.angleToValue(handAngle - i * this.degreesPerUnit, insideClick)
        if (this.isAllowed(value)) return this.setMouseDownValue(value)
      }
    },
    onMouseDown(e) {
      if (this.disabled) return
      e.preventDefault()
      window.addEventListener('mousemove', this.onDragMove)
      window.addEventListener('touchmove', this.onDragMove)
      window.addEventListener('mouseup', this.onMouseUp)
      window.addEventListener('touchend', this.onMouseUp)
      this.valueOnMouseDown = null
      this.valueOnMouseUp = null
      console.log(44444123, 'sadf')
      this.isDragging = true
      this.onDragMove(e)
    },
    onMouseUp(e) {
      e.stopPropagation()
      window.removeEventListener('mousemove', this.onDragMove)
      window.removeEventListener('touchmove', this.onDragMove)
      window.removeEventListener('mouseup', this.onMouseUp)
      window.removeEventListener('touchend', this.onMouseUp)
      this.isDragging = false
      if (this.valueOnMouseUp !== null && this.isAllowed(this.valueOnMouseUp)) {
        this.$emit('change', this.valueOnMouseUp)
      }
    },
  },
}

</script>

<style scoped>
.v-time-picker-clock {
  --time-picker-padding: 24px;
  --time-picker-controls-btn-font: 56px;
  --time-picker-controls-btn-height: 80px;
  --time-picker-controls-btn-width: 96px;
  --time-picker-controls-seconds-btn-width: 64px;
  --time-picker-controls-seconds-btn-height: 80px;
  --time-picker-controls-seconds-btn-font: 40px;
  --time-picker-controls-separator-width: 24px;
  --time-picker-controls-ampm-btn-width: 96px;
  --time-picker-controls-ampm-btn-height: 80px;
  --time-picker-controls-ampm-height: 40px;
  --time-picker-controls-ampm-am-border-radius: 4px 4px 0 0;
  --time-picker-controls-ampm-pm-border-radius: 0 0 4px 4px;
  --time-picker-ampm-title-font-size: 18px;
  --time-picker-number-font-size: 16px;
  --time-picker-indicator-size: 40px;
  --time-picker-clock-padding: 10px;
  --time-picker-clock-max-width: 290px;
  --time-picker-clock-hand-height: calc(50% - 4px);
  --time-picker-clock-hand-width: 2px;
  --time-picker-clock-hand-left: calc(50% - 1px);
  --time-picker-clock-center-size: 8px;
  --time-picker-clock-end-size: 10px;
  --time-picker-clock-end-top: -4px;
  --time-picker-clock-inner-hand-height: 14px;
  --time-picker-clock-inner-offset: 27px;
  --time-picker-clock-end-border-width: 2px;
  --time-picker-clock-end-border-style: solid;
  --time-picker-clock-end-border-color: #008C81;
  --time-picker-width: 328px;
  --time-picker-landscape-title-btn-height: 55px;
  --time-picker-landscape-ampm-title-margin: 16px 0 0;

  --v-theme-background: rgb(255, 255, 255);
  --v-theme-background-overlay-multiplier: 1;
  --v-theme-surface: rgb(255, 255, 255);
  --v-theme-surface-overlay-multiplier: 1;
  --v-theme-surface-bright: rgb(250, 250, 250);
  --v-theme-surface-bright-overlay-multiplier: 1;
  --v-theme-surface-light: rgb(238, 238, 238);
  --v-theme-surface-light-overlay-multiplier: 1;
  --v-theme-surface-variant: #008C81;
  --v-theme-surface-variant-overlay-multiplier: 2;
  --v-theme-on-surface-variant: rgb(238, 238, 238);
  --v-theme-primary: rgb(24, 103, 192);
  --v-theme-primary-overlay-multiplier: 2;
  --v-theme-primary-darken-1: rgb(31, 85, 146);
  --v-theme-primary-darken-1-overlay-multiplier: 2;
  --v-theme-secondary: rgb(92, 187, 246);
  --v-theme-secondary-overlay-multiplier: 1;
  --v-theme-secondary-darken-1: rgb(1, 135, 134);
  --v-theme-secondary-darken-1-overlay-multiplier: 1;
  --v-theme-error: rgb(176, 0, 32);
  --v-theme-error-overlay-multiplier: 2;
  --v-theme-info: rgb(33, 150, 243);
  --v-theme-info-overlay-multiplier: 1;
  --v-theme-success: rgb(76, 175, 80);
  --v-theme-success-overlay-multiplier: 1;
  --v-theme-warning: rgb(251, 140, 0);
  --v-theme-warning-overlay-multiplier: 1;
  --v-theme-surface-variant-alt: rgb(222, 222, 222);
  --v-theme-surface-variant-alt-overlay-multiplier: 1;
  --v-theme-tertiary: rgb(229, 115, 115);
  --v-theme-tertiary-overlay-multiplier: 1;
  --v-theme-accent: rgb(0, 92, 175);
  --v-theme-accent-overlay-multiplier: 2;
  --v-theme-quarternary: rgb(176, 209, 232);
  --v-theme-quarternary-overlay-multiplier: 1;
  --v-theme-on-background: rgb(0, 0, 0);
  --v-theme-on-surface: rgb(0, 0, 0);
  --v-theme-on-surface-bright: rgb(0, 0, 0);
  --v-theme-on-surface-light: rgb(0, 0, 0);
  --v-theme-on-primary: rgb(255, 255, 255);
  --v-theme-on-primary-darken-1: rgb(255, 255, 255);
  --v-theme-on-secondary: rgb(0, 0, 0);
  --v-theme-on-secondary-darken-1: rgb(255, 255, 255);
  --v-theme-on-error: rgb(255, 255, 255);
  --v-theme-on-info: rgb(255, 255, 255);
  --v-theme-on-success: rgb(255, 255, 255);
  --v-theme-on-warning: rgb(255, 255, 255);
  --v-theme-on-surface-variant-alt: rgb(0, 0, 0);
  --v-theme-on-tertiary: rgb(255, 255, 255);
  --v-theme-on-accent: rgb(255, 255, 255);
  --v-theme-on-quarternary: rgb(0, 0, 0);
  --v-disabled-opacity: 0.38;
  --v-theme-kbd: rgb(33, 37, 41);
  --v-theme-on-kbd: rgb(255, 255, 255);
  --v-theme-code: rgb(245, 245, 245);
  --v-theme-on-code: rgb(0, 0, 0);
}

.v-time-picker-clock {
  background: var(--v-theme-background);
  color: var(--v-theme-on-background);
  margin: 0 auto;
  background: var(--v-theme-surface-light);
  border-radius: 50%;
  position: relative;
  transition: none;
  user-select: none;
  height: 256px;
  width: 256px;
  flex: 1 0 auto;
}

.v-time-picker-clock:after {
  color: var(--v-theme-primary);
}

.v-time-picker-clock .v-time-picker-clock__item--active {
  background-color: var(--v-theme-surface-variant);
  color: var(--v-theme-on-surface-variant);
}

.v-time-picker-clock__container {
  display: flex;
  flex-direction: column;
  flex-basis: 290px;
  justify-content: center;
  padding: var(--time-picker-clock-padding);
}

.v-time-picker-clock__hand {
  background-color: #008C81;
  height: var(--time-picker-clock-hand-height);
  width: var(--time-picker-clock-hand-width);
  bottom: 50%;
  left: var(--time-picker-clock-hand-left);
  transform-origin: center bottom;
  position: absolute;
  will-change: transform;
  z-index: 1;
}

.v-time-picker-clock__hand:before {
  background: transparent;
  border-width: var(--time-picker-clock-end-border-width);
  border-style: var(--time-picker-clock-end-border-style);
  border-color: var(--time-picker-clock-end-border-color);
  border-radius: 100%;
  width: var(--time-picker-clock-end-size);
  height: var(--time-picker-clock-end-size);
  content: '';
  position: absolute;
  top: var(--time-picker-clock-end-top);
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-time-picker-clock__hand:after {
  content: '';
  position: absolute;
  height: var(--time-picker-clock-center-size);
  width: var(--time-picker-clock-center-size);
  top: 100%;
  left: 50%;
  border-radius: 100%;
  background-color: #008C81;
  transform: translate(-50%, -50%);
}

.v-time-picker-clock__hand--inner:after {
  height: var(--time-picker-clock-inner-hand-height);
}

.v-time-picker-clock--readonly {
  pointer-events: none;
}

.v-time-picker-clock .v-time-picker-clock__item--disabled {
  opacity: var(--v-disabled-opacity);
}

.v-picker--full-width .v-time-picker-clock__container {
  max-width: var(--time-picker-clock-max-width);
}

.v-time-picker-clock__inner {
  position: absolute;
  bottom: var(--time-picker-clock-inner-offset);
  left: var(--time-picker-clock-inner-offset);
  right: var(--time-picker-clock-inner-offset);
  top: var(--time-picker-clock-inner-offset);
}

.v-time-picker-clock__item {
  align-items: center;
  border-radius: 100%;
  cursor: default;
  display: flex;
  font-size: var(--time-picker-number-font-size);
  justify-content: center;
  height: var(--time-picker-indicator-size);
  position: absolute;
  text-align: center;
  width: var(--time-picker-indicator-size);
  user-select: none;
  transform: translate(-50%, -50%);
}

.v-time-picker-clock__item > span {
  z-index: 1;
}

.v-time-picker-clock__item:before, .v-time-picker-clock__item:after {
  content: '';
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 14px;
  transform: translate(-50%, -50%);
}

.v-time-picker-clock__item:after, .v-time-picker-clock__item:before {
  height: var(--time-picker-indicator-size);
  width: var(--time-picker-indicator-size);
}

.v-time-picker-clock__item--active {
  cursor: default;
  z-index: 2;
}

.v-time-picker-clock__item--disabled {
  pointer-events: none;
}

.v-picker--landscape .v-time-picker-clock__container {
  flex-direction: row;
}
</style>