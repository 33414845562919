var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "clockRef",
    staticClass: "v-time-picker-clock",
    class: {
      'v-time-picker-clock--indeterminate': _vm.modelValue == null,
      'v-time-picker-clock--readonly': _vm.readonly
    },
    on: {
      "mousedown": _vm.onMouseDown,
      "touchstart": _vm.onMouseDown,
      "wheel": _vm.wheel
    }
  }, [_c('div', {
    ref: "innerClockRef",
    staticClass: "v-time-picker-clock__inner"
  }, [_c('div', {
    staticClass: "v-time-picker-clock__hand",
    class: [{
      'v-time-picker-clock__hand--inner': _vm.isInner(_vm.modelValue)
    }, _vm.textColorClasses],
    style: [{
      transform: "rotate(".concat(_vm.rotate + _vm.degreesPerUnit * (_vm.displayedValue - _vm.min), "deg) scaleY(").concat(_vm.handScale(_vm.displayedValue), ")")
    }, _vm.textColorStyles]
  }), _vm._l(_vm.genChildren, function (value) {
    return _c('div', {
      key: value,
      staticClass: "v-time-picker-clock__item",
      class: Object.assign({
        'v-time-picker-clock__item--active': value === _vm.displayedValue,
        'v-time-picker-clock__item--disabled': _vm.disabled || !_vm.isAllowed(value)
      }, value === _vm.displayedValue ? _vm.backgroundColorClasses : {}),
      style: [_vm.getTransform(value)].concat(value === _vm.displayedValue ? [_vm.backgroundColorStyles] : [])
    }, [_c('span', [_vm._v(_vm._s(_vm.format(value)))])]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }