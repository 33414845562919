<template>
  <c-grid
    gap="16px"
    grid-template-columns="repeat(2, 1fr)"
  >
    <!-- Golongan Makanan -->
    <c-flex
      :id="`foodGroup-${mealTime}`"
      width="100%"
      gap="16px"
      flex-direction="column"
      max-height="530px"
      overflow-y="auto"
    >
      <c-box
        v-for="(menu, i) in _menus"
        :key="i"
        :id="`${mealTime}-${menu?.foodGroup?.replace(/[^a-zA-Z]/g, '')}`"
      >
        <FormMenusFoodGroup
          :item="menu"
          :is-disabled="isDisabled"
          :remaining-calories="remainingCalories"
          :selected-food-group="selectedFoodGroup"
          :meal-time="mealTime"
          @update:item="val => updateItem(menu?.foodGroup, val)"
          @select:food-group="val => onSelectFoodGroup(val)"
          @check-intersection="val => onCheckIntersection(val)"
        />
      </c-box>
    </c-flex>

    <!-- Pilihan Bahan Makanan -->
    <FormMenusMealItems 
      :item="_selectedFoodGroupMenus"
      @update:item="val => updateItem(val?.foodGroup, val)"
    />
  </c-grid>
</template>

<script>
import FormMenusFoodGroup from '@/components/meal-plan/_widgets/form-menus-food-group.vue'
import FormMenusMealItems from '@/components/meal-plan/_widgets/form-menus-meal-items.vue'

export default {
  name: 'FormMealComposition',
  components: {
    FormMenusFoodGroup,
    FormMenusMealItems,
  },
  props: {
    mealTime: {
      type: String,
      default: '',
    },
    menus: {
      type: Array,
      default: () => [],
    },
    remainingCalories: {
      type: [Number, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:menus'],
  data() {
    return {
      selectedFoodGroup: null,
    }
  },
  computed: {
    _menus() {
      const sortedData = this.menus
      sortedData.sort((a, b) => {
        if (a?.isActive && !b?.isActive) return -1
        if (!a?.isActive && b?.isActive) return 1
        return a?.order - b?.order
      })
      return sortedData
    },
    _selectedFoodGroupMenus() {
      return this.menus.find(menu => menu?.foodGroup === this.selectedFoodGroup) || null
    },
  },
  methods: {
    calculationNutritionalPropertiesByPortion(data) {
      const calc = (dataNutritional_) => {
        let result = data.portionDecimal ? (parseFloat(data.portionDecimal) * parseFloat(dataNutritional_)) : null
        if (result && !Number.isInteger(result)) result = result.toFixed(2)
        return result
      }
      return {
        carbohydrate: calc(data?.raw?.carbohydrate),
        fat: calc(data?.raw?.fat),
        fiber: calc(data?.raw?.fiber),
        calories: calc(data?.raw?.calory),
        protein: calc(data?.raw?.protein),
      }
    },
    updateItem(foodGroup, data) {
      const index = this.menus.findIndex((el) => el?.foodGroup === foodGroup)
      if (index === -1) return
      let menus = this.menus
      const resultCalc = this.calculationNutritionalPropertiesByPortion(data)
      menus[index] = {
        ...data,
        ...resultCalc,
      }
      this.$emit('update:menus', menus)
    },
    onSelectFoodGroup(foodGroup) {
      this.selectedFoodGroup = foodGroup
    },
    async checkIntersectionFoodGroup(foodGroup) {
      const rootElement = document.getElementById(`foodGroup-${this.mealTime}`)
      const childElement = document.getElementById(`${this.mealTime}-${foodGroup?.replace(/[^a-zA-Z]/g, '')}`)

      if (!rootElement || !childElement) {
        console.error('Root or child element not found')
        return false
      }

      return new Promise((resolve) => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            resolve(entry.isIntersecting)
            observer.disconnect() // Clean up after checking
          },
          {
            root: rootElement,
            threshold: 0.1, // Adjust threshold as needed
          },
        )

        observer.observe(childElement)
      })
    },
    onCheckIntersection(foodGroup) {
      this.checkIntersectionFoodGroup(foodGroup).then((isIntersecting) => {
        if (!isIntersecting) {
          this.$scrollTo(`#${this.mealTime}-${foodGroup?.replace(/[^a-zA-Z]/g, '')}`, 500, {
            container: `#foodGroup-${this.mealTime}`,
            offset: -10,
            easing: 'ease-in-out',
          })
        }
      })
    },
  },
}
</script>