var render = function () {
  var _vm$item, _vm$item2, _vm$item3, _vm$item4, _vm$item5, _vm$item6, _vm$item7, _vm$item$calories, _vm$item8, _vm$item9, _vm$item10, _vm$item11, _vm$item12, _vm$item13, _vm$item14, _vm$item15, _vm$item16;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "padding-block": "8px",
      "padding-inline": "20px",
      "height": (_vm$item = _vm.item) !== null && _vm$item !== void 0 && _vm$item.isActive ? '70px' : '62px',
      "background-color": _vm.isDisabled || !((_vm$item2 = _vm.item) !== null && _vm$item2 !== void 0 && _vm$item2.isActive) ? 'neutral.lightGray' : _vm.isInvalidState ? _vm.isSelected ? 'danger.50' : 'danger.400' : _vm.isSelected ? 'primary.50' : 'primary.400',
      "color": _vm.isDisabled || !((_vm$item3 = _vm.item) !== null && _vm$item3 !== void 0 && _vm$item3.isActive) ? 'neutral.white' : _vm.isInvalidState ? _vm.isSelected ? 'danger.400' : 'neutral.white' : _vm.isSelected ? 'primary.400' : 'neutral.white',
      "align-items": "center",
      "border-radius": "12px",
      "cursor": _vm.isDisabled || !((_vm$item4 = _vm.item) !== null && _vm$item4 !== void 0 && _vm$item4.isActive) ? 'not-allowed' : 'pointer',
      "transition-duration": "500ms",
      "_hover": {
        backgroundColor: _vm.isDisabled || !((_vm$item5 = _vm.item) !== null && _vm$item5 !== void 0 && _vm$item5.isActive) ? 'neutral.lightGray' : _vm.isInvalidState ? _vm.isSelected ? 'danger.100' : 'danger.500' : _vm.isSelected ? 'primary.100' : 'primary.500'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.onClickMenu(_vm.item);
      }
    }
  }, [_c('c-text', {
    attrs: {
      "self-align": "center",
      "font-size": "18px",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.foodGroup) + " ")]), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [(_vm$item7 = _vm.item) !== null && _vm$item7 !== void 0 && _vm$item7.isActive ? _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.isInvalidState,
      "margin-right": "16px"
    }
  }, [_c('c-flex', [_c('c-button', {
    attrs: {
      "background-color": "#FFF",
      "justify-content": "center",
      "align-items": "center",
      "width": "50px",
      "height": "53px",
      "border-top-left-radius": "8px",
      "border-bottom-left-radius": "8px",
      "border-top-right-radius": "0",
      "border-bottom-right-radius": "0",
      "cursor": "pointer",
      "border-width": "1px",
      "border-style": "solid",
      "border-color": _vm.isInvalidState ? 'danger.400' : 'primary.400',
      "transition-duration": "500ms",
      "_hover": {
        backgroundColor: 'primary.50'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.onAdjustPortion(-0.25);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": "15px",
      "height": "auto"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-minus.svg'),
      "height": "100%",
      "width": "100%",
      "fill": _vm.isInvalidState ? '#D32737' : '#008C81'
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "background-color": "#FFF",
      "border-top-width": "1px",
      "border-bottom-width": "1px",
      "border-top-color": _vm.isInvalidState ? 'danger.400' : '#C4C4C4',
      "border-bottom-color": _vm.isInvalidState ? 'danger.400' : '#C4C4C4',
      "color": _vm.isInvalidState ? 'danger.400' : 'primary.400'
    }
  }, [_c('c-input', {
    attrs: {
      "value": _vm._portion,
      "type": "text",
      "width": "150px",
      "text-align": "center",
      "padding-inline": "40px",
      "font-size": "20px",
      "border-radius": "0px",
      "border": "none",
      "height": "30px",
      "font-weight": "500",
      "_focus": {
        outline: 'none'
      },
      "box-shadow": "none !important"
    },
    on: {
      "keydown": _vm.onKeyDownPortion,
      "input": _vm.debouncePortion
    }
  }), _c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "center",
      "align-items": "center",
      "font-size": "14px",
      "height": "20px"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$calories = (_vm$item8 = _vm.item) === null || _vm$item8 === void 0 ? void 0 : _vm$item8.calories) !== null && _vm$item$calories !== void 0 ? _vm$item$calories : '0') + " kkal "), _vm.isExcessCalories ? _c('c-box', {
    attrs: {
      "as": "span",
      "margin-left": "2px"
    }
  }, [_vm._v(" (berlebih) ")]) : _vm._e()], 1)], 1), _c('c-button', {
    attrs: {
      "background-color": "#FFF",
      "justify-content": "center",
      "align-items": "center",
      "width": "50px",
      "height": "53px",
      "border-top-left-radius": "0",
      "border-bottom-left-radius": "0",
      "border-top-right-radius": "8px",
      "border-bottom-right-radius": "8px",
      "cursor": "pointer",
      "border-width": "1px",
      "border-style": "solid",
      "border-color": _vm.isInvalidState ? 'danger.400' : 'primary.400',
      "transition-duration": "500ms",
      "_hover": {
        backgroundColor: 'primary.50'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.onAdjustPortion(0.25);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": "15px",
      "height": "auto"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-plus.svg'),
      "height": "100%",
      "width": "100%",
      "fill": _vm.isInvalidState ? '#D32737' : '#008C81'
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "height": "100%",
      "align-items": "center",
      "gap": "8px",
      "font-size": "14px",
      "font-weight": "500",
      "margin-right": "16px"
    }
  }, [!((_vm$item9 = _vm.item) !== null && _vm$item9 !== void 0 && _vm$item9.isActive) ? _c('c-text', [_vm._v(" Tidak Aktif ")]) : _vm._e(), _c('c-flex', {
    attrs: {
      "position": "relative",
      "width": "60px",
      "height": "27px",
      "align-items": "center",
      "border-radius": "25px",
      "background-color": (_vm$item10 = _vm.item) !== null && _vm$item10 !== void 0 && _vm$item10.isActive && _vm.isSelected ? _vm.isInvalidState ? 'danger.400' : 'primary.400' : '#FFF',
      "transition-duration": "500ms",
      "_hover": {
        backgroundColor: _vm.isDisabled ? 'neutral.white' : _vm.isInvalidState ? _vm.isSelected ? 'danger.500' : (_vm$item11 = _vm.item) !== null && _vm$item11 !== void 0 && _vm$item11.isActive ? 'danger.50' : 'primary.50' : !((_vm$item12 = _vm.item) !== null && _vm$item12 !== void 0 && _vm$item12.isActive) || !_vm.isSelected ? 'primary.50' : 'primary.500'
      },
      "cursor": _vm.isDisabled ? 'not-allowed' : 'pointer'
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onToggleActive.apply(null, arguments);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "position": "absolute",
      "width": "20px",
      "height": "20px",
      "border-radius": "20px",
      "left": (_vm$item13 = _vm.item) !== null && _vm$item13 !== void 0 && _vm$item13.isActive ? 'unset' : '5px',
      "right": (_vm$item14 = _vm.item) !== null && _vm$item14 !== void 0 && _vm$item14.isActive ? '5px' : 'unset',
      "background-color": !((_vm$item15 = _vm.item) !== null && _vm$item15 !== void 0 && _vm$item15.isActive) ? 'neutral.lightGray' : _vm.isInvalidState ? _vm.isSelected ? 'danger.50' : 'danger.400' : _vm.isSelected ? 'primary.50' : 'primary.400'
    }
  })], 1), (_vm$item16 = _vm.item) !== null && _vm$item16 !== void 0 && _vm$item16.isActive ? _c('c-text', [_vm._v(" Aktif ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }