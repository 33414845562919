<template>
  <c-flex
    justify-content="space-between"
    padding-block="8px"
    padding-inline="20px"
    :height="item?.isActive ? '70px' : '62px'"
    :background-color="isDisabled || !item?.isActive 
      ? 'neutral.lightGray' 
      : isInvalidState 
        ? (isSelected ? 'danger.50' : 'danger.400') 
        : (isSelected ? 'primary.50' : 'primary.400')"

    :color="isDisabled || !item?.isActive 
      ? 'neutral.white' 
      : isInvalidState 
        ? (isSelected ? 'danger.400' : 'neutral.white') 
        : (isSelected ? 'primary.400' : 'neutral.white')"
    align-items="center"
    border-radius="12px"
    :cursor="isDisabled || !item?.isActive ? 'not-allowed' : 'pointer'"
    transition-duration="500ms"
    :_hover="{
      backgroundColor: isDisabled || !item?.isActive 
        ? 'neutral.lightGray' 
        : isInvalidState 
          ? (isSelected ? 'danger.100' : 'danger.500') 
          : (isSelected ? 'primary.100' : 'primary.500')
    }"
    @click="onClickMenu(item)"
  >
    <c-text
      self-align="center"
      font-size="18px"
      font-weight="600"
    >
      {{ item?.foodGroup }}
    </c-text>
    <c-flex
      align-items="center"
    >
      <!-- Input Portion -->
      <c-form-control
        v-if="item?.isActive"
        :is-invalid="isInvalidState"
        margin-right="16px"
      >
        <c-flex>
          <c-button
            background-color="#FFF"
            justify-content="center"
            align-items="center"
            width="50px"
            height="53px"
            border-top-left-radius="8px"
            border-bottom-left-radius="8px"
            border-top-right-radius="0"
            border-bottom-right-radius="0"
            cursor="pointer"
            border-width="1px"
            border-style="solid"
            :border-color="isInvalidState ? 'danger.400' : 'primary.400'"
            transition-duration="500ms"
            :_hover="{
              backgroundColor: 'primary.50',
            }"
            @click="onAdjustPortion(-0.25)"
          >
            <c-box
              width="15px"
              height="auto"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-minus.svg')"
                height="100%"
                width="100%"
                :fill="isInvalidState ? '#D32737' : '#008C81'"
              />
            </c-box>
          </c-button>
          <c-box
            background-color="#FFF"
            border-top-width="1px"
            border-bottom-width="1px"
            :border-top-color="isInvalidState ? 'danger.400' : '#C4C4C4'"
            :border-bottom-color="isInvalidState ? 'danger.400' : '#C4C4C4'"
            :color="isInvalidState ? 'danger.400' : 'primary.400'"
          >
            <c-input
              :value="_portion"
              type="text"
              width="150px"
              text-align="center"
              padding-inline="40px"
              font-size="20px"
              border-radius="0px"
              border="none"
              height="30px"
              font-weight="500"
              :_focus="{
                outline: 'none',
              }"
              box-shadow="none !important"
              @keydown="onKeyDownPortion"
              @input="debouncePortion"
            />
            <c-flex 
              width="100%"
              justify-content="center"
              align-items="center"
              font-size="14px"
              height="20px"
            >
              {{ item?.calories ?? '0' }} kkal
              <c-box
                v-if="isExcessCalories"
                as="span"
                margin-left="2px"
              >
                (berlebih)
              </c-box>
            </c-flex>
          </c-box>
          <c-button
            background-color="#FFF"
            justify-content="center"
            align-items="center"
            width="50px"
            height="53px"
            border-top-left-radius="0"
            border-bottom-left-radius="0"
            border-top-right-radius="8px"
            border-bottom-right-radius="8px"
            cursor="pointer"
            border-width="1px"
            border-style="solid"
            :border-color="isInvalidState ? 'danger.400' : 'primary.400'"
            transition-duration="500ms"
            :_hover="{
              backgroundColor: 'primary.50',
            }"
            @click="onAdjustPortion(0.25)"
          >
            <c-box
              width="15px"
              height="auto"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-plus.svg')"
                height="100%"
                width="100%"
                :fill="isInvalidState ? '#D32737' : '#008C81'"
              />
            </c-box>
          </c-button>
        </c-flex>
      </c-form-control>
                
      <!-- Toggle Skip Meal Time -->
      <c-flex
        height="100%"
        align-items="center"
        gap="8px"
        font-size="14px"
        font-weight="500"
        margin-right="16px"
      >
        <c-text
          v-if="!item?.isActive"
        >
          Tidak Aktif
        </c-text>
        <c-flex
          position="relative"
          width="60px"
          height="27px"
          align-items="center"
          border-radius="25px"
          :background-color="item?.isActive && isSelected 
            ? (isInvalidState ? 'danger.400' : 'primary.400') 
            : '#FFF'"
          transition-duration="500ms"
          :_hover="{
            backgroundColor: isDisabled
              ? 'neutral.white'
              : isInvalidState
                ? (isSelected ? 'danger.500' : 
                  (item?.isActive 
                    ? 'danger.50' 
                    : 'primary.50'))
                : (!item?.isActive || !isSelected ? 'primary.50' : 'primary.500')
          }"
          :cursor="isDisabled ? 'not-allowed' : 'pointer'"
          @click.stop="onToggleActive"
        >
          <c-box
            position="absolute"
            width="20px"
            height="20px"
            border-radius="20px"
            :left="item?.isActive ? 'unset' : '5px'"
            :right="item?.isActive ? '5px' : 'unset'"
            :background-color="!item?.isActive 
              ? 'neutral.lightGray' 
              : isInvalidState 
                ? (isSelected ? 'danger.50' : 'danger.400') 
                : (isSelected ? 'primary.50' : 'primary.400')"
          />
        </c-flex>
        <c-text
          v-if="item?.isActive"
        >
          Aktif
        </c-text>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import _ from 'lodash'
import Fraction from 'fraction.js'
import { convertToFraction } from '@/utils/fraction'

export default {
  name: 'FormMenusMealComposition',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    remainingCalories: {
      type: [Number, null],
      default: null,
    },
    selectedFoodGroup: {
      type: [String, null],
      default: null,
    },
    mealTime: {
      type: String,
      default: '',
    },
  },
  emits: ['update:item', 'select:food-group', 'check-intersection'],
  data() {
    return {
      fraction: null,
      debouncePortion: _.debounce(this.onChangePortion, 1000),
    }
  },
  computed: {
    _portion: {
      get() {
        return this.item?.portion || '0'
      },
      set(val) {
        const portionDecimal = this.convertToDecimal(val)
        const item = {
          ...this.item,
          portion: val,
          portionDecimal,
        }
        this.$emit('update:item', item)
      },
    },
    _portionDecimal() {
      return this.item?.portionDecimal || 0
    },
    isExcessCalories() {
      return (this.remainingCalories && this.remainingCalories < 0)
    },
    isInvalidState() {
      return (this.isExcessCalories || !this.item?.calories)
    },
    isSelected() {
      return this.item?.foodGroup === this.selectedFoodGroup
    },
  },
  methods: {
    convertToFraction,
    onToggleActive() {
      if (!this.isDisabled) {
        var item = {
          ...this.item,
          isActive: !this.item?.isActive,
        }

        if (item.isActive) {
          item = {
            ...item,
            mealItems: this.item.defaultItems,
          }
        }

        this.$emit('update:item', item)

        setTimeout(() => {
          if (item?.isActive) {
            this.$emit('select:food-group', item?.foodGroup)
            this.$emit('check-intersection', item?.foodGroup)
          } else if (!item?.isActive && (this.selectedFoodGroup === item?.foodGroup)) {
            this.$emit('select:food-group', null)
          }
        }, 500)
      }
    },
    onKeyDownPortion(event) {
      const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'Delete', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '/', ' ']
      
      const inputValue = this._portion || ''
      const hasDecimal = inputValue.includes('.')
      const hasSlash = inputValue.includes('/')

      if (
        allowedKeys.includes(event.key) ||
        (event.key === '.' && !hasDecimal && !hasSlash) ||
        (event.key === '/' && !hasSlash)
      ) {
        return
      } else {
        event.preventDefault()
      }
    },
    gcd(a, b) {
      return b ? this.gcd(b, a % b) : a
    },
    normalizeFraction(value) {
      if (value) {
        try {
          const x = new Fraction(value)
          return x.toFraction(true)
        } catch(err) {
          this.$toast({
            title: 'Failed',
            description: 'Format porsi tidak sesuai',
            status: 'error',
            variant: 'subtle',
            position: 'bottom-right',
          })
          return ''
        }
      }
      return value
    },
    covertToFraction(fraction) {
      let value = fraction
      const decimalRegex = /^\d*\.?\d{0,2}$/

      if (value.toString().endsWith('.')) {
        value = value + '0'
      }

      if (decimalRegex.test(value)) {
        let decimal = parseFloat(value)
        if (!isNaN(decimal)) {
          let [integerPart, decimalPart] = decimal.toString().split('.')
          if (!decimalPart) {
            return integerPart
          } else {
            let numerator = parseInt(decimalPart)
            let denominator = Math.pow(10, decimalPart.length)
            let gcd = this.gcd(numerator, denominator)
            numerator = numerator / gcd
            denominator = denominator / gcd
            if (integerPart !== '0') {
              numerator = parseInt(integerPart) * denominator + numerator
            }
            return `${numerator}/${denominator}`
          }
        }
      }
      return value
    },
    onChangePortion(value) {
      const sanitizeValue = this.sanitizePortionValue(value)
      const _portion2 = this.normalizeFraction(sanitizeValue)
      this._portion = _portion2
    },
    sanitizePortionValue(value) {
      let value_ = value.replace(/(^\s*)|(\s*$)/g, '') // remove trailing space in start and end of the string, eg: "   1 1/2   " to "1 1/2"
      
      if (value_.includes('/')) { // check if it's has a slash for fraction
        value_ = value_
          .replace(/\s*\/\s*/g, '/') // replace space between slash character, eg: "1 1  /   2" to "1 1/2"
          .replace(/  +/g, ' ') // replace multiple space to single space, eg: "1  1/2" to "1 1/2"
      } else {
        value_ = value_.replace(/ +/g, '') // remove all space, eg: "0   .  2 5" to "0.25"
      }
      return value_
    },
    convertToDecimal(value) {
      let fraction = value
      const decimalRegex = /^\d*\.?\d{0,2}$/
      const fractionRegex = /[1-9][0-9]*\/[1-9][0-9]*/g
      
      if (fraction.toString().endsWith('/')) {
        fraction = fraction + '1'
      }

      if (decimalRegex.test(fraction)) {
        return fraction
      } else if (fractionRegex.test(fraction)) {
        try {
          const x = new Fraction(fraction)
          return parseFloat(x.n / x.d)
        } catch (error) {
          this.$toast({
            title: 'Error',
            description: `Error converting fraction to decimal: ${error}`,
            status: 'error',
            variant: 'subtle',
            position: 'bottom-right',
          })
          return fraction
        }
      } else {
        return value
      }
    },
    onAdjustPortion(amount) {
      let portionDecimal = (parseFloat(this._portionDecimal) + amount)
      
      if (portionDecimal < 0) {
        portionDecimal = 0
      }

      const portion = this.covertToFraction(portionDecimal)
      const portion2 = this.normalizeFraction(portion)
      let calories = portionDecimal ? (parseFloat(portionDecimal) * parseFloat(this.item?.calory)) : null

      if (calories && !Number.isInteger(calories)) {
        calories = calories.toFixed(2)
      }
      const item = {
        ...this.item,
        calories,
        portion: portion2,
        portionDecimal,
      }
      this.$emit('update:item', item)
    },
    onAdjustPortionMealItems(size) {
      const parseSize = size ? parseFloat(size) : 0
      if (this._portionDecimal) {
        const result = parseFloat(this._portionDecimal) * parseSize

        if (Number.isInteger(result)) {
          return result
        }

        const formattedNumber = result
        return this.convertToFraction(formattedNumber)
      }
      return parseSize
    },
    calcServingSize(size, unit) {
      const parseSize = size ? parseFloat(size) : 0
      if (this._portionDecimal) {
        const result = parseFloat(this._portionDecimal) * parseSize
        return `(${result} ${unit})`
      }
      return ''
    },
    onClickMenu(menu) {
      if (this.isDisabled || !menu?.isActive) return
      this.$emit('select:food-group', menu?.foodGroup)
    },
  },
}
</script>