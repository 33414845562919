var render = function () {
  var _vm$modeValueParse$ho, _vm$modeValueParse$ho2, _vm$modeValueParse$mi, _vm$modeValueParse$mi2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "mb": _vm.isWithoutMarginBottom ? '0' : ['16px', '24px'],
      "is-read-only": _vm.isReadOnly,
      "w": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isDisabled ? '#888' : _vm.isInvalid ? '#D32737' : _vm.value ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto",
      "opacity": _vm.isDisabled ? '1' : ''
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.isRequired ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "position": "relative"
    }
  }, [_c('Popper', {
    attrs: {
      "disabled": _vm.isDisabled,
      "trigger": "clickToOpen",
      "options": {
        placement: 'bottom',
        modifiers: {
          offset: {
            offset: '0,10px'
          }
        }
      }
    }
  }, [_c('c-box', {
    staticClass: "popper",
    staticStyle: {
      "border-radius": "8px !important"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "flex-direction": "column",
      "gap": "16px",
      "padding": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "8px",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "background-color": _vm.showTimePicker === 'hour' ? '#d0d0d0' : '#EEEEEE',
      "size": "lg"
    },
    on: {
      "click": function click($event) {
        _vm.showTimePicker = 'hour';
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$modeValueParse$ho = _vm.modeValueParse.hour) === null || _vm$modeValueParse$ho === void 0 ? void 0 : (_vm$modeValueParse$ho2 = _vm$modeValueParse$ho.toString()) === null || _vm$modeValueParse$ho2 === void 0 ? void 0 : _vm$modeValueParse$ho2.padStart(2, '0')) + " ")]), _c('c-box', {
    attrs: {
      "as": "span",
      "font-weight": "500",
      "font-size": "20px"
    }
  }, [_vm._v(" : ")]), _c('c-button', {
    attrs: {
      "background-color": _vm.showTimePicker === 'minute' ? '#d0d0d0' : '#EEEEEE',
      "size": "lg"
    },
    on: {
      "click": function click($event) {
        _vm.showTimePicker = 'minute';
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$modeValueParse$mi = _vm.modeValueParse.minute) === null || _vm$modeValueParse$mi === void 0 ? void 0 : (_vm$modeValueParse$mi2 = _vm$modeValueParse$mi.toString()) === null || _vm$modeValueParse$mi2 === void 0 ? void 0 : _vm$modeValueParse$mi2.padStart(2, '0')) + " ")])], 1), _c('VuetifyTimePicker', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showTimePicker === 'hour',
      expression: "showTimePicker === 'hour'"
    }],
    attrs: {
      "model-value": _vm.modeValueParse.hour,
      "min": 0,
      "max": 23,
      "step": 1,
      "double": "",
      "scrollable": ""
    },
    on: {
      "change": function change(e) {
        var _e$toString, _vm$modeValueParse$mi3, _vm$modeValueParse$mi4;

        _vm.modelValue = (e === null || e === void 0 ? void 0 : (_e$toString = e.toString()) === null || _e$toString === void 0 ? void 0 : _e$toString.padStart(2, '0')) + ':' + ((_vm$modeValueParse$mi3 = _vm.modeValueParse.minute) === null || _vm$modeValueParse$mi3 === void 0 ? void 0 : (_vm$modeValueParse$mi4 = _vm$modeValueParse$mi3.toString()) === null || _vm$modeValueParse$mi4 === void 0 ? void 0 : _vm$modeValueParse$mi4.padStart(2, '0'));
        _vm.showTimePicker = 'minute';
      },
      "input": function input(e) {
        var _e$toString2, _vm$modeValueParse$mi5, _vm$modeValueParse$mi6;

        _vm.modelValue = (e === null || e === void 0 ? void 0 : (_e$toString2 = e.toString()) === null || _e$toString2 === void 0 ? void 0 : _e$toString2.padStart(2, '0')) + ':' + ((_vm$modeValueParse$mi5 = _vm.modeValueParse.minute) === null || _vm$modeValueParse$mi5 === void 0 ? void 0 : (_vm$modeValueParse$mi6 = _vm$modeValueParse$mi5.toString()) === null || _vm$modeValueParse$mi6 === void 0 ? void 0 : _vm$modeValueParse$mi6.padStart(2, '0'));
      }
    }
  }), _c('VuetifyTimePicker', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showTimePicker === 'minute',
      expression: "showTimePicker === 'minute'"
    }],
    attrs: {
      "model-value": _vm.modeValueParse.minute,
      "min": 0,
      "max": 59,
      "step": 5,
      "scrollable": ""
    },
    on: {
      "change": function change($event) {
        var _vm$modeValueParse$ho3, _vm$modeValueParse$ho4, _$event$toString;

        _vm.modelValue = ((_vm$modeValueParse$ho3 = _vm.modeValueParse.hour) === null || _vm$modeValueParse$ho3 === void 0 ? void 0 : (_vm$modeValueParse$ho4 = _vm$modeValueParse$ho3.toString()) === null || _vm$modeValueParse$ho4 === void 0 ? void 0 : _vm$modeValueParse$ho4.padStart(2, '0')) + ':' + ($event === null || $event === void 0 ? void 0 : (_$event$toString = $event.toString()) === null || _$event$toString === void 0 ? void 0 : _$event$toString.padStart(2, '0'));
      },
      "input": function input($event) {
        var _vm$modeValueParse$ho5, _vm$modeValueParse$ho6, _$event$toString2;

        _vm.modelValue = ((_vm$modeValueParse$ho5 = _vm.modeValueParse.hour) === null || _vm$modeValueParse$ho5 === void 0 ? void 0 : (_vm$modeValueParse$ho6 = _vm$modeValueParse$ho5.toString()) === null || _vm$modeValueParse$ho6 === void 0 ? void 0 : _vm$modeValueParse$ho6.padStart(2, '0')) + ':' + ($event === null || $event === void 0 ? void 0 : (_$event$toString2 = $event.toString()) === null || _$event$toString2 === void 0 ? void 0 : _$event$toString2.padStart(2, '0'));
      }
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "slot": "reference",
      "position": "relative"
    },
    slot: "reference"
  }, [_c('vue-timepicker', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input.display-time': {
          p: '1rem !important',
          height: _vm.getSize.vueTimepicker.height,
          fontSize: _vm.getSize.vueTimepicker.fontSize,
          fontFamily: 'Roboto',
          fontWeight: '500',
          borderRadius: '8px',
          border: '1px solid #888',
          boxShadow: '0 0 0 1px #888'
        },
        '.controls>*': {
          cursor: _vm.isDisabled ? 'not-allowed !important' : 'pointer',
          userSelect: _vm.isDisabled ? 'none !important' : 'all'
        },
        'input:disabled': {
          backgroundColor: '#F2F2F2 !important',
          cursor: 'not-allowed !important'
        }
      },
      expression: "{\n            'input.display-time': {\n              p: '1rem !important',\n              height: getSize.vueTimepicker.height,\n              fontSize: getSize.vueTimepicker.fontSize,\n              fontFamily: 'Roboto',\n              fontWeight: '500',\n              borderRadius: '8px',\n              border: '1px solid #888',\n              boxShadow: '0 0 0 1px #888',\n            },\n            '.controls>*': {\n              cursor: isDisabled ? 'not-allowed !important' : 'pointer',\n              userSelect: isDisabled ? 'none !important' : 'all',\n            },\n            'input:disabled': {\n              backgroundColor: '#F2F2F2 !important',\n              cursor: 'not-allowed !important',\n            },\n          }"
    }],
    ref: "formTime",
    staticClass: "hide-controls",
    attrs: {
      "format": "HH:mm",
      "minute-interval": 1,
      "hour-label": "Jam",
      "minute-label": "Menit",
      "input-width": "100%",
      "close-on-complete": _vm.closeOnComplete,
      "hide-clear-button": false,
      "drop-direction": "up",
      "container-id": "formTime",
      "disabled": _vm.isDisabled,
      "input-class": ['skip-error-style', _vm.isDisabled ? 'disabled' : '', _vm.isInvalid ? 'invalid' : '', _vm.modelValue ? 'has-value' : ''],
      "background-color": _vm.isDisabled ? 'neutral.superLightGray' : 'white',
      "manual-input": "",
      "hide-dropdown": "",
      "fixed-dropdown-button": ""
    },
    on: {
      "blur": _vm.onBlur
    },
    scopedSlots: _vm._u([{
      key: "dropdownButton",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "align": "center"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-clock.svg'),
            "height": _vm.getSize.trailingIcon.height,
            "width": _vm.getSize.trailingIcon.width,
            "fill": _vm.isInvalid ? '#e66673' : _vm.isDisabled ? '#888888' : _vm.modelValue ? '#008C81' : '#555555'
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }), _c('c-box', {
    attrs: {
      "position": "absolute",
      "top": "50%",
      "right": "16px",
      "transform": "translateY(-50%)"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-clock.svg'),
      "height": _vm.getSize.trailingIcon.height,
      "width": _vm.getSize.trailingIcon.width,
      "fill": _vm.isInvalid ? '#e66673' : _vm.isDisabled ? '#888888' : _vm.modelValue ? '#008C81' : '#555555'
    }
  })], 1)], 1)], 1)], 1), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "d": "flex",
      "gap": "8px",
      "align-items": "center",
      "color": _vm.helperTextColor
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": _vm.hasHelperTextClick ? 'pointer' : 'auto'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('helper-text-click');
      }
    }
  }, [_vm.helperTextLogo ? _c('c-box', {
    attrs: {
      "min-height": ['13px', '16px'],
      "min-width": ['13px', '16px'],
      "height": ['13px', '16px'],
      "width": ['13px', '16px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.helperTextLogo,
      "height": "100%",
      "width": "100%",
      "fill": _vm.helperTextColor
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.helperText) + " ")], 1)], 1) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "d": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey,
      attrs: {
        "d": "flex",
        "align-items": "center",
        "gap": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "min-height": ['13px', '16px'],
        "min-width": ['13px', '16px'],
        "height": ['13px', '16px'],
        "width": ['13px', '16px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-warn.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#D32737"
      }
    })], 1), _vm._v(" " + _vm._s(invalidTextValue) + " ")], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }