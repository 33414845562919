var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-grid', {
    attrs: {
      "gap": "16px",
      "grid-template-columns": "repeat(2, 1fr)"
    }
  }, [_c('c-flex', {
    attrs: {
      "id": "foodGroup-".concat(_vm.mealTime),
      "width": "100%",
      "gap": "16px",
      "flex-direction": "column",
      "max-height": "530px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm._menus, function (menu, i) {
    var _menu$foodGroup;

    return _c('c-box', {
      key: i,
      attrs: {
        "id": "".concat(_vm.mealTime, "-").concat(menu === null || menu === void 0 ? void 0 : (_menu$foodGroup = menu.foodGroup) === null || _menu$foodGroup === void 0 ? void 0 : _menu$foodGroup.replace(/[^a-zA-Z]/g, ''))
      }
    }, [_c('FormMenusFoodGroup', {
      attrs: {
        "item": menu,
        "is-disabled": _vm.isDisabled,
        "remaining-calories": _vm.remainingCalories,
        "selected-food-group": _vm.selectedFoodGroup,
        "meal-time": _vm.mealTime
      },
      on: {
        "update:item": function updateItem(val) {
          return _vm.updateItem(menu === null || menu === void 0 ? void 0 : menu.foodGroup, val);
        },
        "select:food-group": function selectFoodGroup(val) {
          return _vm.onSelectFoodGroup(val);
        },
        "check-intersection": function checkIntersection(val) {
          return _vm.onCheckIntersection(val);
        }
      }
    })], 1);
  }), 1), _c('FormMenusMealItems', {
    attrs: {
      "item": _vm._selectedFoodGroupMenus
    },
    on: {
      "update:item": function updateItem(val) {
        return _vm.updateItem(val === null || val === void 0 ? void 0 : val.foodGroup, val);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }