import { withParams } from 'vuelidate'
import { ref } from 'vuelidate/src/validators/common'
import dayjs from 'dayjs'

export default (gteTo, customField) =>
  withParams({ type: 'gte', gteTo, customField }, function(value, parentVm) {
    const compareValue = ref(gteTo, this, parentVm)
    if (!compareValue) {
      return true
    }
    return dayjs(value, 'hh:mm').isAfter(dayjs(compareValue, 'hh:mm'))
  })