import { render, staticRenderFns } from "./form-menus-meal-items.vue?vue&type=template&id=566b1074&"
import script from "./form-menus-meal-items.vue?vue&type=script&lang=js&"
export * from "./form-menus-meal-items.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CCheckbox: require('@chakra-ui/vue').CCheckbox, CImage: require('@chakra-ui/vue').CImage, CGrid: require('@chakra-ui/vue').CGrid, CIcon: require('@chakra-ui/vue').CIcon, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CSelect: require('@chakra-ui/vue').CSelect, CSpinner: require('@chakra-ui/vue').CSpinner})
