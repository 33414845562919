var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-box', {
    attrs: {
      "position": "sticky",
      "top": "calc(84px)",
      "background-color": "white",
      "z-index": "10",
      "box-shadow": "10px 0px 0px white, -10px 0px 0px white"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0",
      "margin-right": "16px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push(Object.assign({}, _vm.$route, {
          name: 'nutri.meal-plan.meal-plan-schedule.detail'
        }));
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('TabsMealPlan', {
    attrs: {
      "mode": "edit",
      "tabs": _vm.subTabs,
      "is-loading": _vm.isLoadingSave || _vm.saveState === null
    }
  }), _c('c-tooltip', {
    attrs: {
      "label": _vm.iconCloud.text,
      "placement": "bottom"
    }
  }, [_c('c-box', {
    class: _vm.iconCloud.state === 'loading' ? 'animate-spin' : '',
    attrs: {
      "width": ['35px'],
      "height": ['35px'],
      "margin-right": "16px",
      "flex-shrink": "0"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCloud.icon,
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-save.svg'),
      "left-svg-icon-color": "white",
      "disabled": !_vm.isValidAllMealPlanForm || _vm.saveState === null || _vm.isLoadingSave
    },
    on: {
      "click": _vm.onSaveDraft
    }
  }, [_vm._v(" Simpan ")])], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "margin-top": "16px"
    }
  }, [!_vm.isProfilGiziValid ? _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "background-color": "danger.50",
      "padding": "6px 10px",
      "border-radius": "8px",
      "align-items": "center",
      "gap": "10px",
      "margin-bottom": "16px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-warn.svg'),
      "height": "20px",
      "width": "20px",
      "fill": "#D32737"
    }
  }), _c('c-text', {
    attrs: {
      "color": "danger.400",
      "font-size": "14px"
    }
  }, [_vm._v(" Selesaikan formulir Profil Gizi terlebih dahulu untuk mengaktifkan formulir ini ")])], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "gap": "8px",
      "padding-block": "24px",
      "position": "sticky",
      "top": "83px",
      "background-color": "white",
      "z-index": "20",
      "box-shadow": _vm.boxShadowSimpleNutrionalInfo
    }
  }, _vm._l(Object.entries(_vm.getSimpleNutritionalInfo).toSorted(function (a, b) {
    return a - b;
  }), function (_ref) {
    var nutri = _ref[0],
        nutriValue = _ref[1];
    return _c('c-box', {
      key: nutri,
      attrs: {
        "padding-right": "12px",
        "width": "100%"
      }
    }, [_c('CardInfoSimpleNutritionalProperty', {
      attrs: {
        "title": nutri,
        "requirement": nutriValue.target,
        "current": nutriValue.current,
        "unit": nutriValue.unit
      }
    })], 1);
  }), 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "margin-top": "16px"
    }
  }, [_c('Observer', {
    attrs: {
      "root-margin": "-350px 0px 0px 0px"
    },
    on: {
      "on-change": _vm.onChange
    }
  }), _vm._l(_vm.constants.steps, function (_step, _i) {
    var _vm$form$_step$value, _vm$form$_step$value2, _vm$form$_step$value3, _vm$form$_step$value4, _vm$form$_step$value5;

    return _c('c-box', {
      key: _step.value,
      attrs: {
        "box-shadow": "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        "border-radius": "12px",
        "background-color": "#FFF",
        "margin-top": _i === 0 ? '8px' : '24px'
      }
    }, [_c('c-flex', {
      attrs: {
        "width": "100%",
        "justify-content": "space-between",
        "align-items": "center",
        "padding-inline": "30px",
        "padding-block": "10px",
        "border-bottom": "1px solid #C4C4C4",
        "background-color": "#FFFCDD66"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "28px",
        "font-weight": "600",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s(_step.label) + " ")]), _c('c-flex', {
      attrs: {
        "align-items": "center",
        "gap": "6px",
        "color": "primary.400"
      }
    }, [_vm._v(" Skip jam makan "), _c('c-box', {
      attrs: {
        "cursor": !_vm.isProfilGiziValid ? 'not-allowed' : 'pointer'
      },
      on: {
        "click": function click($event) {
          return _vm.onToggleSkip(_step.value);
        }
      }
    }, [_c('c-checkbox', {
      attrs: {
        "size": "lg",
        "variant-color": "primary",
        "pointer-events": 'none',
        "is-checked": (_vm$form$_step$value = _vm.form[_step.value]) === null || _vm$form$_step$value === void 0 ? void 0 : _vm$form$_step$value.isSkipped,
        "is-disabled": !_vm.isProfilGiziValid
      }
    })], 1)], 1)], 1), _c('c-box', {
      attrs: {
        "padding": "16px",
        "opacity": (_vm$form$_step$value2 = _vm.form[_step.value]) !== null && _vm$form$_step$value2 !== void 0 && _vm$form$_step$value2.isSkipped ? '0.5' : '1'
      }
    }, [_c('c-grid', {
      attrs: {
        "width": "100%",
        "template-columns": "repeat(4, 1fr)",
        "gap": "16px"
      }
    }, [_c('FormTimeClock', {
      attrs: {
        "label": "Jam Mulai Makan",
        "is-required": "",
        "manual-input": "",
        "close-on-complete": false,
        "is-disabled": ((_vm$form$_step$value3 = _vm.form[_step.value]) === null || _vm$form$_step$value3 === void 0 ? void 0 : _vm$form$_step$value3.isSkipped) || !_vm.isProfilGiziValid,
        "is-invalid": _vm.isInvalidField(_vm.$v.form[_step.value].startTime),
        "invalid-text": _vm.parseErrors('Jam Mulai Makan', _vm.$v.form[_step.value].startTime)
      },
      on: {
        "blur": function blur($event) {
          _vm.$v.form[_step.value].startTime.$touch;
        }
      },
      model: {
        value: _vm.form[_step.value].startTime,
        callback: function callback($$v) {
          _vm.$set(_vm.form[_step.value], "startTime", $$v);
        },
        expression: "form[_step.value].startTime"
      }
    }), _c('FormTimeClock', {
      attrs: {
        "label": "Jam Akhir Makan",
        "is-required": "",
        "manual-input": "",
        "close-on-complete": false,
        "is-disabled": ((_vm$form$_step$value4 = _vm.form[_step.value]) === null || _vm$form$_step$value4 === void 0 ? void 0 : _vm$form$_step$value4.isSkipped) || !_vm.isProfilGiziValid,
        "is-invalid": _vm.isInvalidField(_vm.$v.form[_step.value].endTime),
        "invalid-text": _vm.parseErrors('Jam Akhir Makan', _vm.$v.form[_step.value].endTime)
      },
      on: {
        "blur": function blur($event) {
          _vm.$v.form[_step.value].endTime.$touch;
        }
      },
      model: {
        value: _vm.form[_step.value].endTime,
        callback: function callback($$v) {
          _vm.$set(_vm.form[_step.value], "endTime", $$v);
        },
        expression: "form[_step.value].endTime"
      }
    }), _c('FormInput', {
      attrs: {
        "type": "number",
        "label": "Kebutuhan Jumlah Kalori",
        "placeholder": "Masukkan alokasi % kalori untuk waktu makan ini",
        "is-required": "",
        "is-disabled": ((_vm$form$_step$value5 = _vm.form[_step.value]) === null || _vm$form$_step$value5 === void 0 ? void 0 : _vm$form$_step$value5.isSkipped) || !_vm.isProfilGiziValid,
        "right-element": "%",
        "custom-padding-right-input": "50px",
        "helper-text": _vm.infoTextRemainingCalories,
        "helper-text-type": "info",
        "helper-text-color": "#888",
        "is-helper": "",
        "is-invalid": !_vm.isValidCaloriesNeedPercent || _vm.isInvalidField(_vm.$v.form[_step.value].caloriesNeedPercent),
        "invalid-text": _vm.isValidCaloriesNeedPercent ? _vm.parseErrors('Jumlah Kalori', _vm.$v.form[_step.value].caloriesNeedPercent, _vm.customMessageErrors) : "Kebutuhan jumlah kalori tidak boleh melebihi 110%"
      },
      on: {
        "blur": function blur($event) {
          _vm.$v.form[_step.value].caloriesNeedPercent.$touch;
        }
      },
      model: {
        value: _vm.form[_step.value].caloriesNeedPercent,
        callback: function callback($$v) {
          _vm.$set(_vm.form[_step.value], "caloriesNeedPercent", $$v);
        },
        expression: "form[_step.value].caloriesNeedPercent"
      }
    }), _c('FormInput', {
      attrs: {
        "type": "number",
        "label": "Kkal - Jumlah Kalori",
        "placeholder": "Jumlah Kalori",
        "is-required": "",
        "is-disabled": "",
        "right-element": "Kkal"
      },
      model: {
        value: _vm.form[_step.value].caloriesAmount,
        callback: function callback($$v) {
          _vm.$set(_vm.form[_step.value], "caloriesAmount", $$v);
        },
        expression: "form[_step.value].caloriesAmount"
      }
    })], 1), !_vm.form[_step.value].isSkipped ? _c('c-box', {
      attrs: {
        "margin-bottom": "16px"
      }
    }, [_c('Info', {
      attrs: {
        "variant": "info",
        "text": "Daftar golongan makanan di bawah ini opsional."
      }
    })], 1) : _vm._e(), !_vm.form[_step.value].isSkipped && _vm.remainingTotalMealCompositionCalories(_step.value) && _vm.remainingTotalMealCompositionCalories(_step.value) < 0 || !_vm.form[_step.value].isSkipped && _vm.getIsValidTotalMacroNutrient === -1 ? _c('c-flex', {
      attrs: {
        "gap": "16px",
        "margin-bottom": "16px"
      }
    }, [!_vm.form[_step.value].isSkipped && _vm.remainingTotalMealCompositionCalories(_step.value) && _vm.remainingTotalMealCompositionCalories(_step.value) < 0 ? _c('Info', {
      attrs: {
        "variant": "warning",
        "text": "Porsi yang dimasukkan melebihi kebutuhan jumlah kalori sebesar ".concat(_vm.parseFloatToFixed2(Math.abs(_vm.remainingTotalMealCompositionCalories(_step.value)), 2))
      }
    }) : _vm._e(), !_vm.form[_step.value].isSkipped && _vm.getIsValidTotalMacroNutrient === -1 ? _c('Info', {
      attrs: {
        "variant": "warning",
        "text": "Zat gizi makro yang dimasukkan melebihi kebutuhan"
      }
    }) : _vm._e()], 1) : _vm._e(), _c('FormMealComposition', {
      attrs: {
        "meal-time": _step.value,
        "menus": _vm.form[_step.value].mealComposition,
        "is-disabled": _vm.form[_step.value].isSkipped || !_vm.isProfilGiziValid,
        "remaining-calories": _vm.remainingTotalMealCompositionCalories(_step.value)
      },
      on: {
        "update:menus": function updateMenus(val) {
          return _vm.onChangeMenus(val, _step.value);
        }
      }
    })], 1)], 1);
  })], 2), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Data Rencana Makan berhasil disimpan"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(Object.assign({}, _vm.$route, {
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  bypass: 1
                }
              }));
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalFailedSaveData,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Ups, Gagal Menyimpan Data",
      "description": "Mohon maaf, sepertinya terdapat kesalahan data atau data belum terisi semua. Mohon untuk mengecek ulang data anda."
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalFailedSaveData = false;
            }
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }